.commonHeight {
	overflow-y: hidden !important;
	//background-image: url("../../assets/img/indexBg.png");
	background-image: url("../../assets/img/indexBg.png"),
	url("../../assets/img/shown.png");
	background-repeat: no-repeat, no-repeat;
	background-position: bottom, center;
	background-size: 80%, contain;
	//position: relative;

	/*&:after {
		content: '';
		left: 56%;
		top: 50%;
		width: 265px;
		height: 228px;
		position: absolute;
		transform: translate(-50%, -50%);
		background-color: rgba(13, 177, 214, 0.27);
		box-shadow: 0 0 453px 0 #0db1d6;
		border-radius: 100px;
	}*/

}

.chargeParking {
	border-radius: 8px;
	flex-wrap: wrap;
	width: 100%;

	.park {
		width: 169px;
		height: 40px;
		border: 1px solid #0067A6;
		border-radius: 10px;
		font-weight: bold;
		color: #009EFF;
		line-height: 40px;
		text-align: center;
		margin-bottom: 15px;
		cursor: pointer;
		margin-right: 2%;

		&.active {
			color: #FFFFFF;
			background-color: #0067A6;
		}

		&:last-child {
			margin-right: 0;
		}
	}
}

.rightNum {
	float: left;
	padding: 15px;
	width: 186px;
	border: 1px solid #0067A6;
	border-radius: 10px;
	height: 88%;
	display: flex;
	align-items: center;
	flex-flow: column;
	justify-content: space-between;
	position: relative;
	margin: 0 2px;


	.bottomPosition {
		bottom: -2px;
		border: 3px solid #0067A6;
		border-top-width: 0;
		border-radius: 0 0 10px 10px;
		height: 20px;
		left: -2px;
		right: -2px;
		position: absolute;
	}

}


.number {
	padding: 20px 0;
	display: flex;
	align-items: center;
	border-bottom: 1px solid rgba(45, 140, 240, 0.5);
	flex-flow: column;
	width: 100%;
	line-height: 1.8;

	/*	&:last-child {
			border: none;
			padding-bottom: 0;
		}

		&:first-child {
			padding-top: 0;
		}*/

	.circle {
		width: 32px;
		height: 32px;
		border: 1px solid #19D4AE;
		border-radius: 50%;
		display: block;
		font-size: 16px;
		line-height: 32px;
		margin-right: 14px;
		text-align: center;
		margin-bottom: 5px;

		&.month {
			border-color: #5AB1EF;
		}

		&.quarter {
			border-color: #FFB980;
		}

		&.year {
			border-color: #0067A6;
		}
	}

	&.week {
		color: #19D4AE;
	}

	&.inPark {
		color: #5AB1EF;
	}

	&.outPark {
		color: #0067A6;
	}

	&.lossCoupon {
		color: #FFB980;
	}

	.right {
		span {
			color: #FFFFFF;
			font-weight: 400;
		}

		h2 {
			margin-left: 10px;
			font-size: 1.5em;
		}
	}
}

.rightContent {
	height: 92%;
	float: left;
	margin-left: 20px;
	width: calc(100% - 250px);
	z-index: 2;
}

.time ::v-deep {
	border: 1px solid #0067A6;
	border-radius: 10px;
	padding: 13px;
	position: relative;
	margin: 0 0 12px;
	max-width: 1576px;

	.bottomRight {
		right: -2px;
		bottom: -2px;
		border-radius: 0 0 10px 0;
		border-left-width: 0;
		border-top-width: 0;
	}

	.separate {
		width: 15px;
		height: 2px;
		background-color: #FFFFFF;
		display: inline-block;
		margin: 0 10px;
	}

	.el-input__inner {
		font-weight: bold;
		color: #FFFFFF;
		background: rgba(0, 103, 166, 0.3);
		border: none;
	}

	.el-input__prefix {
		color: #FFFFFF;
	}

	.el-date-editor.el-input, .el-date-editor.el-input__inner {
		width: 120px;
	}

	.timePick {
		display: block;
		font-size: 14px;
		font-weight: bold;
		color: #0067A6;
		margin-right: 18px;

		&.el-date-editor.el-input {
			width: 200px;
		}
	}

	.el-button {
		width: 92px;
		height: 40px;
		margin-left: 18px;
		background: #0067A6;
		border-radius: 6px;
		padding: 0;
		border: none;
		color: #FFFFFF;
	}
}

/*.mt {
	height: 27%;
}*/
.float {
	float: left;
	height: 96%;
}

.common {
	padding: 10px;
	cursor: pointer;
	border: 1px solid #0067A6;
	border-radius: 10px;
	height: 42%;
	margin-bottom: 10px;

	&.maxWidth {
		position: relative;
		width: 600px;
	}

	.rightPosition {
		width: 30%;
		height: 50%;
		border: 3px solid #0067A6;
		position: absolute;
		right: -2px;
		top: -2px;
		border-left-width: 0;
		border-radius: 0 10px 0 0;
		border-bottom-width: 0;
	}

	.bottomPosition {
		width: 20%;
		height: 50%;
		border: 3px solid #0067A6;
		position: absolute;
		right: -2px;
		bottom: -2px;
		border-top-width: 0;
		border-radius: 0 0 10px 0;
		border-left-width: 0;
	}

	&.overnight {
		width: 324px;
		//margin-left: 20px;
		position: relative;
	}

	&:hover {
		border-color: #0067A6;
		box-shadow: 0 3px 8px 0 rgba(0, 103, 166, 0.2);
	}

	&.inOver {
		width: 453px;
	}

	.tit {
		width: 46%;
		height: 32px;
		background: linear-gradient(90deg, rgba(0, 103, 166, 1) 0%, rgba(0, 103, 166, 0) 100%);
		line-height: 32px;
		color: #FFFFFF;
		padding-left: 15px;
		border-radius: 8px 0 0 0;
		font-size: 1em;
		margin-bottom: 10px;
	}

	.leftPark {
		margin: 0 10px;
		height: 100px;
	}

	.frequency {
		color: #373D5B;
		width: 50%;
	}

}

.title {
	font-size: 16px;
	color: #FFFFFF;
	flex-flow: column;
	line-height: 1.8;
	width: 45%;

	span {
		font-size: 20px;
		font-weight: bold;
		color: #19D4AE;
		margin-right: 5px;
	}

	.blue {
		color: #5AB1EF;
	}
}

.echarts {
	width: 98%;
	max-width: 1585px;
	margin-right: 0;
	height: auto;
	position: relative;

}

//边角
.commonRadio {
	width: 20px;
	height: 20px;
	border: 3px solid #0067A6;
	position: absolute;
}

.topPosition {
	top: -2px;
	border-bottom-width: 0;
}

.topLeft {
	left: -2px;
	border-radius: 10px 0 0 0;
	border-right-width: 0;
}

.topRight {
	right: -2px;
	border-radius: 0 10px 0 0;
	border-left-width: 0;
}

/*.bottomLeft {
	left: -2px;
	border-right-width: 0;
	border-radius: 0 0 0 10px;
}
*/
.gateDisplay {
	width: calc(100%  -  1019px);
	//max-width: 600px;
	display: none;
	margin-left: 20px;
}

.gate {
	height: 94%;
	position: relative;
	margin-bottom: 0;
}

@media (min-width: 1910px) {
	.gateDisplay {
		width: 600px;
	}
}

@media (min-width: 1710px) {
	.gateDisplay {
		display: block;
	}
}

@media (min-width: 1600px) {
	.leftFloat {
		width: 46.5%;
	}
}
